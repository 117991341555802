<template>
  <div>
    <!-- banner -->
    <banner></banner>
    <div class="box">
      <!-- 为什么报名线上展会 -->
      <div class="why_online">
        <h1 class="title" style="padding-top: 30px">{{ why_online.title }}</h1>
        <div class="f_box">
          {{$t("wsmbmxszh_msg1")}}
        </div>
      </div>
      <!-- 流程图 -->
      <div class="lc_box">
        <h1 class="title">{{ czlc.title }}</h1>
        <ExhibitionProcess></ExhibitionProcess>
      </div>
      <!-- 参展费用 -->
      <div class="czfy">
        <h1 class="title">{{ czfy.title }}</h1>
        <table class="fy_tbale">
          <thead>
            <th style="width: 145px"></th>
            <th style="width: 264px"></th>
            <th style="width: 331px" class="headMany">
              <span>{{ $t("renminbi") }}</span>
              <span>{{ $t("USD") }}</span>
            </th>
            <th>{{ $t("quanyi") }}</th>
          </thead>
          <tbody>
            <tr>
              <td rowspan="3" class="lable_czs_1">{{ czfy.lable_top }}</td>
              <td style="height: 121px">
                <div class="orange_color size_big">
                  {{ tableData.line_1.left.top }}
                </div>
                <!-- <div class="gray_color size_big">{{tableData.line_1.left.bottom}}</div> -->
              </td>
              <td>
                <div class="money_box">
                  <div>
                    <span class="orange_color money_code">¥</span>
                    <span class="orange_color money_size">2000</span>
                    <span class="orange_color money_unit">RMB</span>
                  </div>
                  <div>
                    <span class="orange_color money_code">$</span>
                    <span class="orange_color money_size">300</span>
                    <span class="orange_color money_unit">USD</span>
                  </div>
                </div>
              </td>
              <td class="msg">
                {{ tableData.line_1.right.conten }}
              </td>
            </tr>
            <tr>
              <td style="height: 245px">
                <div class="size_big grayB">
                  {{ tableData.line_2.left.top }}
                </div>
                <div class="size_big grayB">
                  {{ tableData.line_2.left.bottom }}
                </div>
              </td>
              <td>
                <div class="money_box">
                  <div class="size_big grayB">*</div>
                  <div style="max-width: 130px">
                    <!-- <p class="size_big grayB">{{tableData.line_2.center.top}}</p> -->
                    <!-- <p  class="size_big grayB">{{tableData.line_2.center.bottom}}</p> -->

                    <div>
                      <span class="grayB money_code">$</span>
                      <span class="money_size grayB">900</span>
                      <span class="money_code_size grayB">USD</span>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div
                  class="msg"
                  v-for="(item, i) in tableData.line_2.right"
                  :key="i"
                >
                  {{ item }}
                </div>
              </td>
            </tr>
            <tr>
              <td class="tt" style="height: 110px">
                {{ tableData.line_3.left }}
              </td>
              <td>
                <div class="money_box">
                  <div>
                    <span class="money_code">¥</span>
                    <span class="money_size">1200</span>
                    <span class="money_unit">RMB{{ $t("start") }}</span>
                  </div>
                  <div>
                    <span class="money_code">$</span>
                    <span class="money_size">1800</span>
                    <span class="money_unit">USD</span>
                  </div>
                </div>
              </td>
              <td class="msg">
                {{ tableData.line_3.right }}
              </td>
            </tr>

            <tr>
              <td rowspan="4" class="lable_czs_2">
                <div>{{ $t("on_xszt") }}</div>
                <div>{{ $t("on_zzfw") }}</div>
              </td>
              <td class="tt">{{ this.$t("on_tb_line4_left") }}</td>
              <td>
                <div class="money_box">
                  <div>
                    <span class="money_code">¥</span>
                    <span class="money_size">500</span>
                    <span class="money_code_size money_unit">RMB</span>
                  </div>
                  <div>
                    <span class="money_code">$</span>
                    <span class="money_size">75</span>
                    <span class="money_code_size money_unit">USD</span>
                  </div>
                </div>
              </td>
              <td class="msg">
                {{ this.$t("on_tb_line4_right") }}
              </td>
            </tr>
            <tr>
              <td class="tt">{{ $t("on_tb_line5_left") }}</td>
              <td>
                <div class="money_box">
                  <div>
                    <span class="money_code">¥</span>
                    <span class="money_size">1000</span>
                    <span class="money_code_size money_unit">RMB</span>
                  </div>
                  <div>
                    <span class="money_code">$</span>
                    <span class="money_size">150</span>
                    <span class="money_code_size money_unit">USD</span>
                  </div>
                </div>
              </td>
              <td class="msg">{{ $t("on_tb_line5_right") }}</td>
            </tr>
            <tr>
              <td class="tt">{{ $t("on_tb_line6_left") }}</td>
              <td>
                <div class="money_box">
                  <div class="t_b">
                    <div>
                      <span class="money_code">¥</span>
                      <span class="money_size">600</span>
                      <span class="money_code_size money_unit">RMB</span>
                    </div>

                    <!-- <div  class="money_size">{{$t('on_tb_line6_center_top')}} </div> -->
                    <!-- <div  class="money_size">{{$t('on_tb_line6_center_bottom')}}</div> -->
                  </div>
                  <div>
                    <span class="money_code">$</span>
                    <span class="money_size">100</span>
                    <span class="money_code_size money_unit">USD</span>
                  </div>
                </div>
              </td>
              <td class="msg">{{ $t("on_tb_line6_right") }}</td>
            </tr>
            <tr>
              <td class="tt">{{ $t("on_tb_line7_left") }}</td>
              <td>
                <div class="money_box">
                  <div>
                    <span class="money_code_size money_code">¥</span>
                    <span class="money_size">6800</span>
                    <span class="money_code_size money_unit">RMB</span>
                  </div>
                  <div>
                    <span class="money_code_size money_code">$</span>
                    <span class="money_size">1,000</span>
                    <span class="money_code_size money_unit">USD</span>
                  </div>
                </div>
              </td>
              <td class="msg">{{ $t("on_tb_line7_right") }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- 成为云展赞助商 -->
      <div class="become">
        <h1 class="title">{{ become.title }}</h1>
        <div class="b_box">
          <div v-for="(item, i) in become.data" :key="i">
            <div class="content">
              <h1 class="s_title">{{ item.lable }}</h1>
              <div class="ss">{{ item.small_1 }}</div>
              <div class="ss">{{ item.small_2 }}</div>
              <div class="ss">
                <span v-if="item.small_3.lable != ''" class="ss">{{
                  item.small_3.lable
                }}</span>
                <span class="orange">{{ item.small_3.orange_left }}</span>
                <span class="orange">{{ item.small_3.orange_right }}</span>
              </div>
              <div v-if="item.small_4" class="ss">
                <span class="ss">{{ item.small_4.lable }}</span>
                <span class="orange">{{ item.small_4.orange_left }}</span>
                <span class="orange">{{ item.small_4.orange_right }}</span>
              </div>
            </div>
            <img :src="item.img" alt="" />
          </div>
        </div>
      </div>
      <!-- 查看更多 -->
      <div class="look_more">
        <el-button class="more" @click="companyLookMore">{{
          $t("Learn_more")
        }}</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import banner from "~exp/components/common/banner";
import ExhibitionProcess from "~exp/components/common/Exhibition_process";
export default {
  components: {
    banner,
    ExhibitionProcess,
  },
  data() {
    return {
      //为什么报名线上展会
      why_online: {
        title: this.$t("wsmbmxszh"),
        content: [
          {
            msg: this.$t("wsmbmxszh_msg1"),
            background:
              "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/online/why_left.png",
          },
          {
            msg: this.$t("wsmbmxszh_msg2"),
            background:
              "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/online/why_center.png",
          },
          {
            msg: this.$t("wsmbmxszh_msg3"),
            background:
              "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/online/why_right.png",
          },
        ],
      },
      // 参展流程
      czlc: {
        title: this.$t("on_czlc"),
      },
      // 参展费用
      czfy: {
        title: this.$t("off_czfy"),
        lable_top: this.$t("on_czs"),
        lable_bottom: {
          top: this.$t("on_xszt"),
          bottom: this.$t("on_zzfw"),
        },
      },
      tableData: {
        line_1: {
          left: {
            top: this.$t("on_xsffzt"),
            bottom: this.$t("on_bxhnw"),
          },
          right: {
            conten: this.$t("on_tb_lin1_1"),
          },
        },
        line_2: {
          left: {
            top: this.$t("on_tb_mini"),
            bottom: this.$t("on_tb_hwzh"),
          },
          center: {
            top: this.$t("on_tb_jgxj"),
            bottom: this.$t("on_tb_xxmp"),
          },
          //  this.$t('')
          right: [
            this.$t("on_tb_line2_content1"),
            this.$t("on_tb_line2_content2"),
            this.$t("on_tb_line2_content3"),
            this.$t("on_tb_line2_content4"),
          ],
        },
        line_3: {
          left: this.$t("on_tb_line3_left"),
          right: this.$t("on_tb_line3_right"),
        },
      },
      // 成为云展赞助商
      become: {
        title: this.$t("on_become_yzs"),
        data: [
          {
            lable: this.$t("on_become_item1_lable"),
            small_1: this.$t("on_become_item1_small_1"),
            small_2: this.$t("on_become_item1_small_2"),
            small_3: {
              lable: this.$t("on_become_item1_small_3_lable"),
              orange_left: this.$t("on_become_item1_small_3_orange_left"),
              orange_right: this.$t("on_become_item1_small_3_orange_right"),
            },
            small_4: {
              lable: this.$t("on_become_item1_small_4_lable"),
              orange_left: this.$t("on_become_item1_small_4_orange_left"),
              orange_right: this.$t("on_become_item1_small_4_orange_right"),
            },
            img: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/online/be_left.png",
          },
          {
            lable: this.$t("on_become_item2_lable"),
            small_1: this.$t("on_become_item2_small_1"),
            small_2: this.$t("on_become_item2_small_2"),
            small_3: {
              lable: this.$t("on_become_item2_small_3_lable"),
              orange_left: "¥10000 RMB",
              orange_right: "$1500 USD",
            },
            small_4: null,
            img:
              this.LOCALE == "zh"
                ? "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/online/be_center.png"
                : "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/online/be_center_en.png",
          },
          {
            lable: this.$t("on_become_item3_lable"),
            small_1: this.$t("on_become_item3_small_1"),
            small_2: this.$t("on_become_item3_small_2"),
            small_3: {
              lable_zh: "",
              lable_en: "",
              orange_left: "¥15000 RMB",
              orange_right: "$2250 USD",
            },
            small_4: null,
            img: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/online/be_right.png",
          },
        ],
      },
    };
  },
  mounted() {},
  methods: {
    companyLookMore() {
      this.$router.push({ path: "/sponsorChance" });
    },
  },
};
</script>
<style lang="less" scoped>
.box {
  width: 1300px;
  margin: auto;
  text-align: center;
  .title {
    font-size: 24px;
    font-weight: 800;
    color: #333333;
    padding-top: 60px;
    padding-bottom: 34px;
  }
  .why_online {
    .f_box {
      font-size: 16px;
      font-weight: 400;
    }
  }
  .fy_tbale {
    width: 1300px;
    thead th {
      height: 58px;
      background: #f7f7f7;

      font-size: 18px;
      font-weight: 800;
      color: #1f292e;
    }
    .headMany {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
    .lable_czs_1 {
      width: 145px;
      height: 478px;
      background: #2292ff;
      font-size: 21px;
      font-weight: 800;
      color: #ffffff;
    }
    .lable_czs_2 {
      width: 145px;
      height: 343px;
      background: #bddb85;
      div {
        font-size: 21px;
        font-weight: 800;
        color: #ffffff;
      }
    }
    .orange_color {
      color: #dd9740;
    }
    .gray_color {
      color: #909399;
    }
    .grayB {
      color: #677b85;
    }
    .size_big {
      font-size: 18px;
      font-weight: 800;
    }
    .msg {
      font-size: 14px;
      font-weight: 400;
      color: #374049;
      line-height: 26px;
      text-align: left;
      padding-left: 35px;
      padding-right: 42px;
    }
    .tt {
      font-size: 18px;
      font-weight: 800;
      color: #1f292e;
    }
    .money_box {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
    .money_size {
      font-size: 18px;
      font-weight: 800;
      display: inline-block;
      padding-right: 6px;
    }
    .money_code_size {
      font-size: 14px;
      font-weight: 500;
    }
    .t_b {
      display: flex;
      flex-direction: column;
    }
    td {
      border: 1px solid #e2e2e2;
    }
  }

  //成为云展赞助商
  .become {
    .b_box {
      display: flex;
      justify-content: space-between;
      .s_title {
        font-size: 16px;
        font-weight: 800;
        color: #333333;
        padding-bottom: 12px;
      }
      .ss {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: #333333;
      }
      .orange {
        color: #dd9740;
        display: inline-block;
        font-size: 13px;
        font-weight: 400;
        padding-left: 2px;
        padding-right: 10px;
      }
      .content {
        height: 130px;
        margin-bottom: 24px;
      }
    }
  }
  //查看更多
  .look_more {
    display: flex;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 70px;
    .more {
      height: 40px;
      width: 180px;
    }
  }
}
.money_code {
  font-size: 14px;
  font-weight: 800;
}
.money_unit {
  font-size: 12px;
  font-weight: 400;
}
</style>